import { render, staticRenderFns } from "./BookOfRaDeluxeModal.vue?vue&type=template&id=32749972&scoped=true&"
import script from "./BookOfRaDeluxeModal.vue?vue&type=script&lang=js&"
export * from "./BookOfRaDeluxeModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32749972",
  null
  
)

export default component.exports